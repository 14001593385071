export const environment = {
	production: false,
	environmentName: 'Develop',
	userAuth: {
		aws_project_region: 'us-east-1',
		aws_cognito_region: 'us-east-1',
		aws_cognito_identity_pool_id: 'us-east-1:928a6a7b-d637-43f6-bc24-c60908107f75',
		aws_user_pools_id: 'us-east-1_OsWMEp9xw',
		aws_user_pools_web_client_id: '5sopi741fuoragteoos9rjaecj',
		oauth: {
			domain: 'jabil-pip-dev-scm-procurement.auth.us-east-1.amazoncognito.com',
			scope: ['email', 'openid'],
			redirectSignIn: `https://${window.location.host}/login/preauth`,
			redirectSignOut: `https://${window.location.host}/`,
			redirectUri: `https://${window.location.host}`,
			responseType: 'code',
			options: {
				AdvancedSecurityDataCollectionFlag: true
			}
		}
	},
	signInUrl: {
		authorize_url: 'https://jabil-pip-dev-scm-procurement.auth.us-east-1.amazoncognito.com/authorize',
		response_type: 'code',
		client_id: '5sopi741fuoragteoos9rjaecj',
		redirect_uri: `https://${window.location.host}/login/preauth`
	},
	endpoints: {
		apiUrl: 'https://thrfpkjfig.execute-api.us-east-1.amazonaws.com/dev/',
		wsUrl: 'https://krpxkp5tid.execute-api.us-east-1.amazonaws.com/dev/'
	},
	s3thumbnailsUpload:
		'https://us-east-1.console.aws.amazon.com/s3/buckets/s3-jbl-dev-uea1-std-scm-procurement-mi-exports?region=us-east-1&bucketType=general&prefix=thumbnails-import/&showversions=false',
	logo: {
		imagePath: 'assets/img/logo/mi6_logo.svg'
	},
	agGridLicenseKey:
		'Using_this_{AG_Grid}_Enterprise_key_{AG-063222}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Jabil_Inc._(USA,_St._Petersburg,_FL,_33716,_Roosevelt_Boulevard)}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Mechanical_Intelligence}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Mechanical_Intelligence}_need_to_be_licensed___{Mechanical_Intelligence}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{9_August_2025}____[v3]_[01]_MTc1NDY5NDAwMDAwMA==57f921dba4ef619a459486f0df053171'
};
